import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../layout"
import { generateElectramImage } from "../components/Configuradores/imageUtils/electram"

// Components
import SEO from "../components/SEO"
import { Configurador } from "../components/Configuradores/Configurador"
import Electram from "../components/Configuradores/inputs/electram.json"
import { useTranslation } from "gatsby-plugin-react-i18next"

const ConfigElectram = ({ location }) => {
  const { t } = useTranslation()

  const configuradorForm = t("configurador", { returnObjects: true })
  const userForm = t("form", { returnObjects: true })

  const [finalImg, setFinalImg] = useState("")

  const handleFinalImg = inputs => {
    let doors = inputs["numero-portas-por-camaras"],
      width = inputs["largura-porta"],
      chambers = inputs["numero-camaras"],
      height = inputs["altura-camara"]

    setFinalImg(generateElectramImage(doors, width, chambers, height))
  }

  return (
    <Layout
      location={location}
      footer={t("footer", { returnObjects: true })}
      header={t("header", { returnObjects: true })}
      hideBr
    >
      <SEO title="Electram" bodyClass={"configurador"}></SEO>
      <Configurador
        inputsJson={Electram}
        configuradorForm={configuradorForm}
        userForm={userForm}
        email={configuradorForm["email"]}
        oven={"Electram"}
        finalImg={finalImg}
        handleFinalImg={handleFinalImg}
      />
    </Layout>
  )
}

export default ConfigElectram

export const ConfiguradorElectram = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["electram", "footer", "header", "form"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
